/* eslint sonarjs/cognitive-complexity: 1 */
import {
  type Category,
  type RpcContext,
  slugify,
  type RFC33339Date,
  type ParamRpcHandler,
  unwrap,
} from '@scayle/storefront-nuxt'
import { group } from 'radash'
import { getRootCategories } from './categories'
import { getDataSourceEntries, getCmsStories } from './cms'
import { getCustomData } from './customData'
import { type Attribute, getAttributes } from './attributes'
import { logMessage } from './logging'

export declare type AbstractNavigationItem = {
  id: number
  type: string
  assets: {
    [key: string]: string
  }
  name: string
  visibleFrom: RFC33339Date | null
  visibleTo: RFC33339Date | null

  children: NavigationItems
}

export declare type NavigationItemExternal = AbstractNavigationItem & {
  type: 'external' | 'individual-link'
  options: {
    url: string
    isOpenInNewWindows: boolean
  }
}

export type FilterValueBoolean = { include: boolean }
export type FilterValueArray = { include: string[] | number[] }
export type FilterValue = FilterValueArray | FilterValueBoolean

export type ExtraFilterDeprecated = {
  [key: string]: FilterValue
} & { attributes?: never }

export type ExtraAttributeFilterDeprecated = {
  attributes: ExtraFilterDeprecated
}

export type ExtraFiltersDeprecated = Array<
  ExtraFilterDeprecated | ExtraAttributeFilterDeprecated
>

export type ExtraFilterAttribute = {
  attribute: Attribute
} & FilterValue

export type ExtraFilters = {
  attributes?: ExtraFilterAttribute[]
  new?: FilterValueBoolean
  merchants?: FilterValueArray

  master_categories?: FilterValueArray
}

export declare type NavigationItemCategory = AbstractNavigationItem & {
  type: 'category'
  extraFilters: ExtraFilters | ExtraFiltersDeprecated
  categoryId: number
  category?: Category
}
export declare type NavigationItemPage = AbstractNavigationItem & {
  type: 'page'
  page: string
}

export type NavigationItem =
  | NavigationItemExternal
  | NavigationItemCategory
  | NavigationItemPage

export declare type NavigationItems = NavigationItem[]

export declare type NavigationTree = {
  id: number
  key: string
  name: string
  items: NavigationItems
}

export type NavigationLink = {
  type: 'internal' | 'external'
  label: AbstractNavigationItem['name']
  slug: string
  href: string
  target?: '_self' | '_blank' | '_parent' | '_top'
  assets: AbstractNavigationItem['assets']
  children: NavigationLink[]
  visibleFrom: AbstractNavigationItem['visibleFrom']
  visibleTo: AbstractNavigationItem['visibleTo']
  navigationOrder?: number
}

type NavigationTreeConfig = {
  treeId?: number
  useCustomTree?: boolean
}

export const flattenCategories = (categories: Category[]): Category[] =>
  categories
    .flatMap((category) => [
      category,
      ...flattenCategories(category.children ?? []),
    ])
    .filter((category) => !category.isHidden)

const filterKeyMapping: Record<string, string> = {
  new: 'isNew',
  merchants: 'merchantId',
  master_categories: 'category',
}

const getFilterKeyMapping = (key: string) => filterKeyMapping?.[key] ?? key

export const buildFilterQuery = (
  filters: NavigationItemCategory['extraFilters'],
  attributeMapping: Map<number, string> = new Map<number, string>(),
) => {
  let filter: Record<string, string[] | number[] | boolean> = {}

  // Deprecated, leave for backwards compatibility
  if (Array.isArray(filters)) {
    filter =
      filters?.reduce<Record<string, any>>((res, filter) => {
        return Object.entries(filter).reduce(
          (res, [filterKey, filterValue]) => {
            // Skip everything besides 'attributes' as this needs to be defined
            if (
              filterKey !== 'attributes' &&
              !Object.keys(filterKeyMapping).includes(filterKey)
            ) {
              return res
            }

            if (filterValue.include) {
              return {
                ...res,
                [getFilterKeyMapping(filterKey)]: filterValue.include,
              }
            } else {
              return Object.entries(filter?.attributes ?? {}).reduce(
                (result, [key, value]) => {
                  const attributeKey =
                    attributeMapping?.get(parseFloat(key)) ?? key

                  if (Array.isArray(value?.include)) {
                    const current = result?.[attributeKey] ?? []
                    return {
                      ...result,
                      [attributeKey]: [
                        ...new Set([...value.include, ...current]),
                      ],
                    }
                  }

                  return result
                },
                res,
              )
            }
          },
          res,
        )
      }, {}) ?? {}
  } else {
    filter =
      Object.entries(filters || {}).reduce<Record<string, any>>(
        (params, [key, value]) => {
          // Handle Attributes differently
          if (key === 'attributes' && Array.isArray(value)) {
            return value.reduce<Record<string, any>>((attributes, filter) => {
              if (Array.isArray(filter.include)) {
                const { [filter.attribute.key]: current = [] } = attributes
                return {
                  ...attributes,
                  [filter.attribute.key]: [...current, ...filter.include],
                }
              }

              return {
                ...attributes,
                [filter.attribute.key]: filter.include,
              }
            }, params)
          }

          // Map every known system filter
          if (
            !Array.isArray(value) &&
            value?.include &&
            Object.keys(filterKeyMapping).includes(key)
          ) {
            return {
              ...params,
              [getFilterKeyMapping(key)]: value.include,
            }
          }

          // Skip everything besides 'attributes' and known system filters
          return params
        },
        {},
      ) ?? {}
  }

  if (Object.keys(filter).length) {
    const params = new URLSearchParams()

    Object.entries(filter).forEach(([key, value]) => {
      params.set(key, Array.isArray(value) ? value.join(',') : `${value}`)
    })

    return `?${params.toString()}`
  }

  return ''
}

const getCategoryHref = (
  path: string,
  filters: NavigationItemCategory['extraFilters'],
  attributeMapping: Map<number, string> = new Map<number, string>(),
): string => {
  return path + buildFilterQuery(filters, attributeMapping)
}

const buildStoryblokNavigationLinks = (
  pages: any[],
  navigationGroupDatasource: any[],
): NavigationLink[] => {
  // Group pages under a common navigation group like "Der Weg zum Horsystem" and remove the pages without a group(navigation_group === undefined)
  const navigationGroups: Partial<Record<any, any[]>> = group(
    pages.filter((g) => g.content.navigation_group),
    (g) => g.content.navigation_group,
  )

  const buildChildren = (
    pages: any[],
    baseFolder = '/content',
  ): NavigationLink[] => {
    const DEFAULT_NAV_ORDER = 99
    return pages?.map((p) => {
      const isExternalPage = p.content.component === 'externalPage'
      const hasBaseFolderInFullSlug = p.full_slug?.indexOf(baseFolder) > -1

      const [_localeFolder, pagePath] = hasBaseFolderInFullSlug
        ? (p.full_slug?.split(baseFolder) ?? ['', '/'])
        : ['', '/'] // defaults pagePath to '/' when no full_slug or baseFolder is not present in that full_slug
      const path = isExternalPage ? p.content?.external_url : pagePath
      const type: NavigationLink['type'] = isExternalPage
        ? 'external'
        : 'internal'

      return {
        type,
        label: p.name,
        slug: p.slug,
        children: [],
        childrenIds: [],
        href: path ?? '/',
        assets: {},
        visibleFrom: null,
        visibleTo: null,
        navigationOrder: p.content?.navigation_order || DEFAULT_NAV_ORDER,
      }
    })
  }

  return navigationGroupDatasource
    ?.map((ds) => ds.value || ds.name)
    ?.map((navGroupName) => {
      const children = buildChildren(
        navigationGroups[navGroupName] ?? [],
      )?.sort((a, b) => Number(a.navigationOrder) - Number(b.navigationOrder))
      return {
        type: 'internal',
        label: navGroupName,
        slug: navGroupName,
        children,
        href: '/',
        assets: {},
        visibleFrom: null,
        visibleTo: null,
      } as NavigationLink
    })
    .filter((i) => i?.children?.length)
}

/**
 * Hardcoded fallback navigation
 * @param context
 * @returns
 */
const buildNavigationFromStoryblok = async (
  cmsBaseFolder: string,
  context: RpcContext,
): Promise<NavigationLink[]> => {
  const result: NavigationLink[] = []

  try {
    // get data from storyblok
    const [hoerakustikPages, servicePages, navigationGroupDatasource] =
      await Promise.allSettled([
        getCmsStories(
          {
            folder: 'hoerakustik',
            baseFolder: cmsBaseFolder,
            params: { per_page: 100 },
          },
          context,
        ),
        getCmsStories(
          {
            folder: 'service',
            baseFolder: cmsBaseFolder,
            params: { per_page: 100 },
          },
          context,
        ),
        getDataSourceEntries({ datasource: 'navigation-group' }, context),
      ])

    if (
      navigationGroupDatasource.status === 'fulfilled' &&
      hoerakustikPages.status === 'fulfilled'
    ) {
      result.push({
        type: 'internal',
        href: '/hoerakustik',
        slug: 'hoerakustik',
        label: 'Hörakustik',
        assets: {},
        visibleTo: null,
        visibleFrom: null,
        children: buildStoryblokNavigationLinks(
          hoerakustikPages.value,
          navigationGroupDatasource.value,
        ),
      })
    }

    if (
      navigationGroupDatasource.status === 'fulfilled' &&
      servicePages.status === 'fulfilled'
    ) {
      result.push({
        type: 'internal',
        href: '/service',
        slug: 'service',
        label: 'Service',
        assets: {},
        visibleFrom: null,
        visibleTo: null,
        children: buildStoryblokNavigationLinks(
          servicePages.value,
          navigationGroupDatasource.value,
        ),
      })
    }
  } catch {
    // todo add logger
  }

  return result
}

export const buildNavigationFromCategories = (
  categories: Category[],
): NavigationLink[] =>
  categories.map((category) => ({
    type: 'internal',
    href: category.path,
    slug: category.slug,
    assets: {},
    label: category.name,
    visibleFrom: null,
    visibleTo: null,
    children: buildNavigationFromCategories(category?.children ?? []),
  }))

export const buildNavigationFromTree = (
  items: NavigationTree['items'],
  categories: Category[],
  attributeMapping: Map<number, string> = new Map<number, string>(),
  context: RpcContext,
): NavigationLink[] =>
  items
    // eslint-disable-next-line sonarjs/cognitive-complexity
    ?.map((item) => {
      const result: NavigationLink = {
        type: 'internal',
        href: '',
        slug: slugify(item.name),
        assets: item.assets,
        label: item.name,
        visibleFrom: item.visibleFrom,
        visibleTo: item.visibleTo,
        children: buildNavigationFromTree(
          item?.children ?? [],
          categories,
          attributeMapping,
          context
        ),
      }

      if (item.type === 'category') {
        const category =
          item.category ||
          categories.find((category) => category.id === item.categoryId)

        if (category) {
          result.href = getCategoryHref(
            category.path,
            item.extraFilters,
            attributeMapping,
          )
          result.slug = category.slug

          if (!result.label) {
            result.label = category.name
          }
        }
      }

      if (item.type === 'external' || item.type === 'individual-link') {
        try {
          const url = new URL(item.options.url)
          if (url.hostname === 'storyblok.fielmann') {
            result.href = `${url.pathname}${url.search}${url.hash}`
          } else {
            result.type = 'external'
            result.href = url.toString()
          }

          if (item.options.isOpenInNewWindows) {
            result.target = '_blank'
          }
        } catch (error) {
          logMessage(
            {
              message: error,
              level: 'error',
              extras: {
                why: 'buildNavigationFromTree',
                where: 'navigation.ts',
              },
            },
            context,
          )
        }
      }

      return result
    })
    ?.filter((item) => Boolean(item.href)) ?? []

type FetchNavigationParams = {
  shopId: number
  id: number
}
const fetchNavigation = async (
  params: FetchNavigationParams,
  context: RpcContext,
): Promise<NavigationTree> => {
  try {
    return (await context.bapiClient.navigation.getById(params.id, {
      with: {
        category: true,
      },
    })) as NavigationTree
  } catch (error) {
    logMessage(
      {
        message: error,
        level: 'error',
        extras: {
          why: 'context.bapiClient.navigation.getById(',
          where: 'navigation.ts',
          id: params.id,
        },
      },
      context,
    )

    return {
      id: 0,
      key: '',
      name: '',
      items: [],
    }
  }
}

export const needsExternalCategories = (items: NavigationItems): boolean =>
  items.some((item: NavigationItem) => {
    if (item.type === 'category') {
      return !item.category
    }

    return needsExternalCategories(item.children)
  })

export const needsExternalAttributes = (items: NavigationItems): boolean =>
  items.some((item: NavigationItem) => {
    const check =
      item.type === 'category' &&
      item.extraFilters &&
      Array.isArray(item.extraFilters)

    return check || needsExternalAttributes(item.children)
  })

export const getFimNavigationTree: ParamRpcHandler<
  {
    cmsBaseFolder?: string
  },
  NavigationLink[]
> = async (config, context: RpcContext): Promise<NavigationLink[]> => {
  const { cached, bapiClient, shopId } = context

  const customData = await getCustomData(context)

  const { treeId, useCustomTree }: NavigationTreeConfig =
    customData?.navigationTree ?? {}

  // Build navigation from customTree if available
  if (treeId && useCustomTree) {
    try {
      // use this one again after the SDK has been updated
      // const navigationTree = await cached(bapiClient.navigation.getById, {
      //   ttl: 3600,
      //   cacheKey: `NAVIGATION:${treeId}`,
      // })(treeId)

      const navigationTree = await cached(fetchNavigation, {
        ttl: 3600,
        cacheKey: `NAVIGATION:${treeId}`,
      })({ id: treeId, shopId }, context)

      // try to resolve attributeMapping
      const attributes = needsExternalAttributes(navigationTree?.items ?? [])
        ? await unwrap(getAttributes(context))
        : []
      const attributeMapping = new Map(
        attributes.map((attribute) => [attribute.id, attribute.key]),
      )

      const allCategories = needsExternalCategories(navigationTree?.items ?? [])
        ? await cached(bapiClient.categories.getRoots, {
            cacheKeyPrefix: 'bapiClient.categories.getRoots',
          })({
            with: { children: 3, properties: 'all' },
            includeHidden: true,
          })
        : []

      if (navigationTree?.items?.length) {
        return buildNavigationFromTree(
          (navigationTree?.items ?? []) as unknown as NavigationItems,
          flattenCategories(allCategories),
          attributeMapping,
          context
        )
      }
    } catch (error) {
      logMessage({
        message: error,
        level: 'error',
        extras: {
          why: 'getFimNavigationTree',
          where: 'navigation.ts',
        },
      }, context)
    }
  }

  // Fallback to categories & "old" storyblok implementation
  const categories = await unwrap(getRootCategories({ children: 3 }, context))
  const storyblokLinks = await buildNavigationFromStoryblok(
    config.cmsBaseFolder || '',
    context,
  )

  return [
    ...buildNavigationFromCategories(categories.categories),
    ...storyblokLinks,
  ]
}
